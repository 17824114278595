<template>
  <div id="Plans" class="flex" cols="12" sm="12">
    <!-- <h1>SpookApp Plans</h1> -->
    <!-- <v-form v-model="valid" ref="form" lazy-validation> -->
    <v-container>
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="pricingTable">
            <div class="pricingTable-header">
              <div class="icon">
                <v-icon>mdi-emoticon-happy</v-icon>
                <!-- <i class="fas fa-bicycle"></i> -->
              </div>
              <div class="price-value">
                <span class="currency" v-if="isIndia">₹</span>
                <span class="currency" v-if="!isIndia">$</span>
                <span class="amount">0</span>
              </div>
            </div>
            <div class="pricing-content">
              <h3 class="title">Standard</h3>
              <ul>
                <li>FLASHLIGHT TURN ON</li>
                <li>MUSIC PLAY</li>
                <li>VIBRATION</li>
                <li>BRIGHTNESS</li>
                <li>CAMERA OPEN</li>
                <li>BLUETOOTH</li>
                <li>SOUND</li>
                <li>HOSTPOT OPEN</li>
                <li>SCREENSHOT TAKE</li>
                <li>TURBO SEARCH</li>

                <li class="disable">SMS</li>
                <li class="disable">CONTACT LIST</li>
                <li class="disable">ON CALL</li>
                <li class="disable">CALL LOGS</li>
                <li class="disable">INSTALL APP</li>

                <li class="disable">WHATSAPP</li>
                <li class="disable">INSTAGRAM</li>
              </ul>
              <div class="pricingTable-signup">
                <a class="disabledLink" @click="pay('basic')">Life Time</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="pricingTable platinum">
            <div class="pricingTable-header">
              <div class="icon">
                <v-icon>mdi-emoticon-cool</v-icon>
                <!-- <i class="fas fa-bicycle"></i> -->
              </div>
              <div class="price-value">
                <span class="currency" v-if="isIndia">₹</span>
                <span class="currency" v-if="!isIndia">$</span>
                <span class="amount">{{ plantium }}</span>
              </div>
            </div>
            <div class="pricing-content">
              <h3 class="title">PLANTIUM</h3>
              <ul>
                <li>FLASHLIGHT TURN ON</li>
                <li>MUSIC PLAY</li>
                <li>VIBRATION</li>
                <li>BRIGHTNESS</li>
                <li>CAMERA OPEN</li>
                <li>BLUETOOTH</li>
                <li>SOUND</li>
                <li>HOSTPOT OPEN</li>
                <li>SCREENSHOT TAKE</li>
                <li>TURBO SEARCH</li>

                <li>SMS</li>
                <li>CONTACT LIST</li>
                <li>ON CALL</li>
                <li>CALL LOGS</li>
                <li>INSTALL APP</li>

                <li>WHATSAPP</li>
                <li>INSTAGRAM</li>
              </ul>

              <div class="pricingTable-signup">
                <a
                  href="#"
                  v-if="isIndia"
                  v-bind:class="isPlantiumDisable ? 'disabledLink' : ''"
                  @click="pay('plantium')"
                  >Pay</a
                >
                <br /><br />
                <div v-if="!isIndia" v-bind:class="isPlantiumDisable ? 'disabledLink' : ''">
                  <div ref="paypal"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="pricingTable yellow">
            <div class="pricingTable-header">
              <div class="icon">
                <v-icon>mdi-emoticon</v-icon>
              </div>
              <div class="price-value">
                <span class="currency" v-if="isIndia">₹</span>
                <span class="currency" v-if="!isIndia">$</span>
                <span class="amount">{{ gold }}</span>
              </div>
            </div>
            <div class="pricing-content">
              <!-- <div v-if="!paidFor">
                <h1>Buy this Lamp - ${{ product.price }} OBO</h1>

                <p>{{ product.description }}</p>

                <img width="400" src="https://images-na.ssl-images-amazon.com/images/I/61yZD4-mKjL._SX425_.jpg" />
              </div>

              <div v-if="paidFor">
                <h1>Noice, you bought a beautiful lamp!</h1>

                <img src="https://media.giphy.com/media/j5QcmXoFWl4Q0/giphy.gif" />
              </div> -->

              <!-- <div ref="paypal"></div> -->

              <h3 class="title">GOLD</h3>
              <ul>
                <li>FLASHLIGHT TURN ON</li>
                <li>MUSIC PLAY</li>
                <li>VIBRATION</li>
                <li>BRIGHTNESS</li>
                <li>CAMERA OPEN</li>
                <li>BLUETOOTH</li>
                <li>SOUND</li>
                <li>HOSTPOT OPEN</li>
                <li>SCREENSHOT TAKE</li>
                <li>TURBO SEARCH</li>

                <li>SMS</li>
                <li>CONTACT LIST</li>
                <li>ON CALL</li>
                <li>CALL LOGS</li>
                <li>INSTALL APP</li>

                <li class="disable">WHATSAPP</li>
                <li class="disable">INSTAGRAM</li>
              </ul>
              <div class="pricingTable-signup">
                <a href="#" v-if="isIndia" v-bind:class="isGoldDisable ? 'disabledLink' : ''" @click="pay('gold')"
                  >Pay</a
                >
                <br /><br />
                <div v-if="!isIndia" v-bind:class="isGoldDisable ? 'disabledLink' : ''">
                  <div ref="paypalgold"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-col cols="12" sm="4" md="4"></v-col>
        <v-col cols="12" sm="4" md="4">
          <v-switch v-model="switch1" inset color="red" :label="`Yearly ?`"></v-switch>
        </v-col>
        <v-col cols="12" sm="4" md="4"></v-col>
      </div>

      <!-- <v-row>
          <v-col cols="12" md="4">   
            <center style="font-size: 40px;">
              <v-btn color="success" class="mr-4" @click="saveBrightness()">Save & Play</v-btn>
              <br />
            </center>
          </v-col>
            <v-col cols="12" md="4">   
            <center style="font-size: 40px;">
              <v-btn color="success" class="mr-4" @click="saveBrightness()">Save & Play</v-btn>
              <br />
            </center>
          </v-col>
             <v-col cols="12" md="4">   
            <center style="font-size: 40px;">
              <v-btn color="success" class="mr-4" @click="saveBrightness()">Save & Play</v-btn>
              <br />
            </center>
          </v-col>
      </v-row>-->

      <v-snackbar v-model="snackbar" :timeout="timeout" :right="x === 'right'" :top="y === 'top'" :color="color">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
/* import { appConfig } from "../../firebaseConfig"; */
import { collection, addDoc } from 'firebase/firestore'
import { dbRef, planClick } from '../../../firebaseConfig'
import { getUserDataInfo } from '../../../localStorage'
import { getUID, getCurrentDeviceSelected, getPrices, setPrices } from '../../../localStorage'
import { activate, fetchConfig, getRemoteConfig } from 'firebase/remote-config'
import { getValue, fetchAndActivate } from 'firebase/remote-config'
import store from '@/store'
const axios = require('axios')

export default {
  name: 'Plans',
  data() {
    return {
      paypal: {
        sandbox: 'Abjml2IhENS-4aIw1oJ2zFrVUb1NKmQ5rBiLE1GrjxVXXR_Xlh4Pq1gUywSkxWvmW5ONMzWUd8hDS_iB',
        production: 'Abjml2IhENS-4aIw1oJ2zFrVUb1NKmQ5rBiLE1GrjxVXXR_Xlh4Pq1gUywSkxWvmW5ONMzWUd8hDS_iB',
      },
      paypalgold: {
        sandbox: 'Abjml2IhENS-4aIw1oJ2zFrVUb1NKmQ5rBiLE1GrjxVXXR_Xlh4Pq1gUywSkxWvmW5ONMzWUd8hDS_iB',
        production: 'Abjml2IhENS-4aIw1oJ2zFrVUb1NKmQ5rBiLE1GrjxVXXR_Xlh4Pq1gUywSkxWvmW5ONMzWUd8hDS_iB',
      },
      isIndia: false,
      loading: false,
      loaded: false,
      paidFor: false,
      product: {
        price: 777.77,
        description: 'leg lamp from that one movie',
        img: './assets/lamp.jpg',
      },
      gold: 29,
      plantium: 49,
      goldYear: 324.9,
      plantiumYear: 548.9,
      switch1: false,
      length: 5,
      valid: false,
      selectedPlan: '',
      search: '',
      musicUrl: '',
      musicRules: [v => !!v || 'Music URL is required', v => /\.(?:wav|mp3)$/i.test(v) || 'Music must be valid'],
      selected: null,
      notificationValues: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      remoteValue: null,
      myitems: [],
      latitude: '',
      longitude: '',
      isPlantiumDisable: false,
      isGoldDisable: false,
      obj: {
        appVibration: {
          seconds: '',
          vibration: '',
        },
        battryPercentage: '',
        isCharging: '',
        bluetooth: '',
        brightness: '',
        flashlight: '',
        mobileData: '',
        mobileDataType: '',
        musicUrl: {
          music: '',
          url: '',
        },
        sound: '',
      },
      color: '',
      mode: '',
      snackbar: false,
      text: "Hello, I'm a snackbar",
      timeout: 6000,
      x: null,
      y: 'top',
      amount: 0,
    }
  },
  mounted: function () {
    planClick()
    this.getCurrentPlan()
    this.getRemoteConfigValues()
    this.isIndiaCheck()
    this.getRemoteConfigValues()
    this.the()
    const script = document.createElement('script')
    script.src =
      'https://www.paypal.com/sdk/js?client-id=Abjml2IhENS-4aIw1oJ2zFrVUb1NKmQ5rBiLE1GrjxVXXR_Xlh4Pq1gUywSkxWvmW5ONMzWUd8hDS_iB'
    script.addEventListener('load', this.setLoaded)
    document.body.appendChild(script)
  },
  watch: {
    switch1(switch1) {
      //called whenever switch1 changes
      console.log(switch1)
      if (switch1) {
        console.log('yearly')
        var mux = this.gold * 12
        var muxp = this.plantium * 12
        if (this.isIndia) {
          this.gold = this.remoteValue.YEAR.INR[0].GOLD
          this.plantium = this.remoteValue.YEAR.INR[1].PLATINUM
        } else {
          this.gold = this.remoteValue.YEAR.USD[0].GOLD
          this.plantium = this.remoteValue.YEAR.USD[1].PLATINUM
        }
        //  this.gold = mux - mux / 15
        //this.plantium = muxp - muxp / 15
      } else {
        if (this.isIndia) {
          this.gold = this.remoteValue.MONTH.INR[0].GOLD
          this.plantium = this.remoteValue.MONTH.INR[1].PLATINUM
        } else {
          this.gold = this.remoteValue.MONTH.USD[0].GOLD
          this.plantium = this.remoteValue.MONTH.USD[1].PLATINUM
        }
      }
    },
  },
  methods: {
    async isIndiaCheck() {
      axios
        .get('https://api.ipify.org/?format=json')
        .then(res => {
          console.log(res)
          if (res.status == 200) {
            axios
              .get('https://thread-frost-buffet.glitch.me/cool?ip=' + res.data.ip)
              .then(res => {
                console.log(res)
                if (res.status == 200) {
                  if (res.data.country == 'India') {
                    this.isIndia = true
                    let sss = getPrices()
                    this.remoteValue = JSON.parse(sss)
                    if (this.isIndia) {
                      this.gold = this.remoteValue.MONTH.INR[0].GOLD
                      this.plantium = this.remoteValue.MONTH.INR[1].PLATINUM
                    } else {
                      this.gold = this.remoteValue.MONTH.USD[0].GOLD
                      this.plantium = this.remoteValue.MONTH.USD[1].PLATINUM
                    }
                  }
                }
              })
              .catch(err => {
                console.error(err)
              })
              .finally(res => {
                this.$store.commit('LOADER', false)
              })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    async the() {},
    setLoaded: function () {
      this.loaded = true
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'Plantium Plan',
                  amount: {
                    currency_code: 'USD',
                    value: this.plantium,
                  },
                },
              ],
            })
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture()

            this.data

            this.paidFor = true

            console.log(order)

            // Now Check existing payment available or not
            // const docRef = doc(dbRef, 'payment', user.uid)
            var exDate
            var exTs
            if (this.switch1) {
              exDate = this.addMonths(new Date(), 12)
              exTs = this.addMonths(new Date(), 12).getTime()
            } else {
              exDate = this.addMonths(new Date(), 1)
              exTs = this.addMonths(new Date(), 1).getTime()
            }

            let user = getUserDataInfo()
            addDoc(collection(dbRef, 'payment'), {
              uid: user.uid,
              email: user.email,
              emailVerified: user.emailVerified,
              plan: 'plantium',
              currencyCode: 'USD',
              gateway: 'paypal',
              razorpay: JSON.stringify(order),
              razorpayverify: JSON.stringify(order),
              createdDate: new Date().toDateString(),
              createdTS: new Date().getTime(),
              expiryDate: exDate,
              expiryTS: exTs,
              yearly: this.switch1,
              amount: this.plantium,
            })
              .then(result => {
                console.log(result)
                this.swal(true, result)
              })
              .catch(error => {
                value = false
                myerror = error.message
                this.swal(false, error.message)
              })
              .finally(res => {
                this.$store.commit('LOADER', false)
              })
          },
          onError: err => {
            console.log(err)
          },
        })
        .render(this.$refs.paypal)
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'Gold Plan',
                  amount: {
                    currency_code: 'USD',
                    value: this.gold,
                  },
                },
              ],
            })
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture()

            this.data

            this.paidFor = true

            console.log(order)
            //   console.log(verifyResponse)

            // Now Check existing payment available or not
            // const docRef = doc(dbRef, 'payment', user.uid)
            var exDate
            var exTs
            if (this.switch1) {
              exDate = this.addMonths(new Date(), 12)
              exTs = this.addMonths(new Date(), 12).getTime()
            } else {
              exDate = this.addMonths(new Date(), 1)
              exTs = this.addMonths(new Date(), 1).getTime()
            }

            let user = getUserDataInfo()
            addDoc(collection(dbRef, 'payment'), {
              uid: user.uid,
              email: user.email,
              emailVerified: user.emailVerified,
              plan: 'gold',
              currencyCode: 'USD',
              gateway: 'paypal',
              razorpay: JSON.stringify(order),
              razorpayverify: JSON.stringify(order),
              createdDate: new Date().toDateString(),
              createdTS: new Date().getTime(),
              expiryDate: exDate,
              expiryTS: exTs,
              yearly: this.switch1,
              amount: this.gold,
            })
              .then(result => {
                console.log(result)
                this.swal(true, result)
              })
              .catch(error => {
                value = false
                myerror = error.message
                this.swal(false, error.message)
              })
              .finally(res => {
                this.$store.commit('LOADER', false)
              })
          },
          onError: err => {
            console.log(err)
          },
        })
        .render(this.$refs.paypalgold)
    },
    async getRemoteConfigValues() {
      const remoteConfig = getRemoteConfig()
      remoteConfig.settings.minimumFetchIntervalMillis = 10
      remoteConfig.defaultConfig = {
        is_subscribe: 'false',
      }
      await fetchAndActivate(remoteConfig)
        .then(res => {
          console.log(res)
          const vals = getValue(remoteConfig, 'prices')
          console.log('setPrices')
          console.log(vals)
          debugger
          setPrices(vals._value)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(res => {
          console.log('getPrices')
          let sss = getPrices()
          this.remoteValue = JSON.parse(sss)
          if (this.isIndia) {
            this.gold = this.remoteValue.MONTH.INR[0].GOLD
            this.plantium = this.remoteValue.MONTH.INR[1].PLATINUM
          } else {
            this.gold = this.remoteValue.MONTH.USD[0].GOLD
            this.plantium = this.remoteValue.MONTH.USD[1].PLATINUM
          }
        })
      const vals = getValue(remoteConfig, 'prices')
      console.log(vals)
    },
    async pay(val) {
      this.$store.commit('LOADER', true)
      this.selectedPlan = val
      if (val == 'basic') {
        this.amount = 0
      } else if (val == 'gold') {
        this.amount = this.gold
      } else {
        this.amount = this.plantium
      }

      console.log(val)
      // Customer details and amount
      let userData = getUserDataInfo()

      const data = {
        amount: this.amount,
        name: userData.displayName,
        email: userData.email,
        // phone: '9662705684',
      }
     console.log(val);
     let checkIsType=this.switch1 == true ? 'Year' : 'Month';
     let descriptionRazor= val + " Plan " + checkIsType;
     console.log(descriptionRazor);
      axios
        .post('https://spookapi.kumawat.co.in/razorpay', data)
        .then(res => {
          console.log(res)
          let order = res.data
          var options = {
            key: 'rzp_live_JynWbnskmmoWwY', // Enter the Key ID generated from the Dashboard
            name: 'SpookApp',
            description:descriptionRazor,
            image: 'https://cdn.razorpay.com/logos/IaNQTyc22YdFBv_large.jpg',
            order_id: order.id,
            prefill: {
              name: data.name,
              email: data.email,
              contact: data.phone,
            },
            theme: {
              color: '#000000', // Set your website theme color
            },
            handler: response => {
              this.verifySignature(response)
            },
          }
          var rzp = new Razorpay(options)
          rzp.open()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    async verifySignature(response) {
      console.log('verifySignature')
      console.log(response)
      axios
        .post('https://spookapi.kumawat.co.in/verification', response)
        .then(verifyResponse => {
          console.log(verifyResponse)

          // Now Check existing payment available or not
          // const docRef = doc(dbRef, 'payment', user.uid)
          var exDate
          var exTs
          if (this.switch1) {
            exDate = this.addMonths(new Date(), 12)
            exTs = this.addMonths(new Date(), 12).getTime()
          } else {
            exDate = this.addMonths(new Date(), 1)
            exTs = this.addMonths(new Date(), 1).getTime()
          }

          let user = getUserDataInfo()
          addDoc(collection(dbRef, 'payment'), {
            uid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            plan: this.selectedPlan,
            currencyCode: 'INR',
            gateway: 'razorpay',
            razorpay: JSON.stringify(response),
            razorpayverify: JSON.stringify(verifyResponse),
            createdDate: new Date().toDateString(),
            createdTS: new Date().getTime(),
            expiryDate: exDate,
            expiryTS: exTs,
            yearly: this.switch1,
            amount: this.amount,
          })
            .then(result => {
              console.log(result)
              this.swal(true, result)
            })
            .catch(error => {
              value = false
              myerror = error.message
              this.swal(false, error.message)
            })
            .finally(res => {
              this.$store.commit('LOADER', false)
            })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    addMonths(date, months) {
      var d = date.getDate()
      date.setMonth(date.getMonth() + +months)
      if (date.getDate() != d) {
        date.setDate(0)
      }
      return date
    },
    async swal(value, error) {
      //  console.log(constant.error.hello);
      if (value == true) {
        if (error == '' || error == undefined) {
          this.$swal.fire('success', 'Email send successfully', 'success')
        } else {
          this.$swal.fire('success', error, 'Payment successfully, Please logout and re login.')
        }
      } else if (value == null) {
        //  console.log("null");
      } else {
        // console.log("value get" + error);
        if (error == '') {
          //   console.log("null value get" + error);
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        } else {
          //  console.log("not null value get" + error);
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          })
        }
      }
    },
    getCurrentPlan() {
      let currentPlant = store.state.user.roles.permission
      console.log(currentPlant)
      if (currentPlant == 'Platinum') {
        this.isPlantiumDisable = true
        this.isGoldDisable = true
      }
      if (currentPlant == 'Gold') {
        this.isPlantiumDisable = false
        this.isGoldDisable = true
      }
    },
  },
}
</script>
<style scoped>
.disabledLink {
  pointer-events: none;
  cursor: default;
}
.switch-center {
  display: flex;
  justify-content: center;
}
.pricingTable {
  background: #4ec2f4;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 0 20px 20px;
  margin: 50px 0 0;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}
.pricingTable:after {
  content: '';
  background: #26a9f9;
  width: 100%;
  height: 60%;
  transform: translateY(-50%);
  position: absolute;
  top: 230px;
  left: 0;
  z-index: -2;
  clip-path: polygon(0 45%, 100% 0%, 100% 100%, 0 65%);
}
.pricingTable .pricingTable-header {
  padding: 15px;
}
.pricingTable .icon {
  color: #fff;
  background: #22a2f6;
  font-size: 30px;
  width: 100px;
  padding: 7px 0 2px;
  border-radius: 15px 15px 0 0;
  transform: translateX(-50%);
  position: absolute;
  top: -50px;
  left: 50%;
}
.pricingTable .price-value {
  color: #fff;
  background: linear-gradient(to right, #4ec2f4, #26a9f9);
  font-weight: 600;
  height: 140px;
  width: 140px;
  padding: 35px 5px 0;
  margin: 0 auto;
  border: 15px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.pricingTable .price-value:before {
  content: '';
  background: linear-gradient(to right, #26a9f9, #4ec2f4);
  width: 95px;
  height: 95px;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
}
.pricingTable .price-value .currency {
  font-size: 40px;
  line-height: 20px;
  vertical-align: top;
  display: inline-block;
}
.pricingTable .price-value .amount {
  font-size: 26px;
  line-height: 27px;
  display: inline-block;
}
.pricingTable .pricing-content {
  background-color: #fff;
  padding: 15px 0 20px;
  border-radius: 20px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}
.pricingTable .title {
  color: #26a9f9;
  font-size: 33px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px;
}
.pricingTable .pricing-content ul {
  padding: 0;
  margin: 0 0 30px;
  list-style: none;
  display: inline-block;
}
.pricingTable .pricing-content ul li {
  color: #000;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
  padding: 0 0 0 30px;
  margin: 0 0 12px;
  position: relative;
}
.pricingTable .pricing-content ul li:last-child {
  margin-bottom: 0;
}
.pricingTable .pricing-content ul li:before {
  content: '✓';
  color: #fff;
  background: #26a9f9;
  font-family: 'Font Awesome 5 Free';
  font-size: 13px;
  font-weight: 900;
  text-align: center;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.pricingTable .pricing-content li.disable:before {
  content: '✗';
  background: #d1d1d1;
}
.pricingTable .pricingTable-signup a {
  color: #fff;
  background: #26a9f9;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 20px;
  border-radius: 15px 15px;
  transition: all 0.3s;
}
.pricingTable .pricingTable-signup a:hover {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  box-shadow: 0 -7px 0 rgba(0, 0, 0, 0.1);
}
.pricingTable.yellow {
  background: #fdbe0e;
}
.pricingTable.yellow:after,
.pricingTable.yellow .icon,
.pricingTable.yellow .pricing-content ul li:before,
.pricingTable.yellow .pricingTable-signup a {
  background: #faa20c;
}
.pricingTable.yellow .price-value {
  background: linear-gradient(to right, #fdbe0e, #faa20c);
}
.pricingTable.yellow .price-value:before {
  background: linear-gradient(to right, #faa20c, #fdbe0e);
}
.pricingTable.yellow .pricing-content li.disable:before {
  background: #d1d1d1;
}
.pricingTable.yellow .title {
  color: #faa20c;
}

.pricingTable.platinum {
  background: #c1beba;
}
.pricingTable.platinum:after,
.pricingTable.platinum .icon,
.pricingTable.platinum .pricing-content ul li:before,
.pricingTable.platinum .pricingTable-signup a {
  background: #86938d;
}
.pricingTable.platinum .price-value {
  background: linear-gradient(to right, #b0b8bb, #7c8984);
}
.pricingTable.platinum .price-value:before {
  background: linear-gradient(to right, #606b66, #ffffff);
}
.pricingTable.platinum .pricing-content li.disable:before {
  background: #565656;
}
.pricingTable.platinum .title {
  color: #191b1a;
}

.pricingTable.pink {
  background: #f42c68;
}
.pricingTable.pink:after,
.pricingTable.pink .icon,
.pricingTable.pink .pricing-content ul li:before,
.pricingTable.pink .pricingTable-signup a {
  background: #e20845;
}
.pricingTable.pink .price-value {
  background: linear-gradient(to right, #f42c68, #e20845);
}
.pricingTable.pink .price-value:before {
  background: linear-gradient(to right, #e20845, #f42c68);
}
.pricingTable.pink .pricing-content li.disable:before {
  background: #d1d1d1;
}
.pricingTable.pink .title {
  color: #e20845;
}
@media only screen and (max-width: 990px) {
  .pricingTable {
    margin-bottom: 80px;
  }
}
</style>
